import { useEffect, useState } from "react";
import Button from '@mui/material/Button'
import LoadIcon from '../LoadIcon/LoadIcon';
import InfoIcon from '@mui/icons-material/Info';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import "./AllBands.scss";

export type BandObject = {
    id: number,
    name: string
}

function AllBands() {

    const [bands, setBands] = useState([]);

    useEffect(() => {
        fetch('https://ba.studioprimary.com/api/getallbands')
            .then(response => response.json())
            .then(data => setBands(data))
            .catch(() => console.log('failed to fetch'));
    }, []);

    if (bands.length === 0 || bands === null) {
        return (
            <div>
                <h1 className="header">Bands page</h1>
                <div className="loading"><LoadIcon /></div>
            </div>
        );
    }

    const cols: GridColDef[] = [
        { field: 'name', headerName: 'Naam:', flex: 3 },
        { field: 'id', headerName: 'Info', flex: 0.3, sortable: false,
            renderCell: (params: GridRenderCellParams<number>) => (
                <Link to={'/band/' + params.value}>
                    <InfoIcon className="infoIcon" />
                </Link>
            )
        },
    ];

    return (
        // <div>
        //     <Grid container spacing={2}>
        //         <Grid item xs={8}>
        //             <div>
        //                 <h1>All bands</h1>
        //                 {bands.map(band => (
        //                     <div>
        //                         <div>
        //                             <Link to={{pathname: '/band/' + band.name}} style={{ textDecoration: 'none', color: "black" }}>
        //                             <h3>{band.name}</h3>
        //                             </Link>
        //                         </div>
        //                     </div>
        //                 ))}
        //             </div>
        //         </Grid>
        //         <Grid item xs={2}>
        //             <br/>
        //             <div>
        //                 <Link to="/createband" style={{ textDecoration: 'none' }}>
        //                     <Button variant='outlined' color='secondary'>Create Band</Button>
        //                 </Link>
        //             </div>
        //         </Grid>
        //     </Grid>
        // </div>
            <div>
                <h1 className="header">Bands page</h1>
                <DataGrid
                    className="datagrid"
                    rows={bands}
                    columns={cols}
                    autoHeight
                    disableColumnMenu />
                <Link to="/createband" className="createButton">
                    <Button variant='outlined' color='secondary'>Create Band</Button>
                </Link>
            </div>
    );
}

export default AllBands
