import { useEffect, useState, useContext } from 'react';
import { UserContext } from 'context/UserContext';
import ApiEndpoints, { baseUrl } from '../../api/api-endpoints';
import Button from '@mui/material/Button';
import EventForm from './EventForm';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import LoadIcon from '../LoadIcon/LoadIcon';
import MapIcon from '@mui/icons-material/Map';
import GroupsIcon from '@mui/icons-material/Groups';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@mui/material';

type Event = {
  name: string;
  dateTime: Date;
  location: string;
};

function EventsList() {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [openDialog, setOpenDialog] = useState(false);

  const context = useContext(UserContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchAllEvents = () => {
    setLoading(true);
    console.log(page);
    fetch(`${baseUrl}/${ApiEndpoints.events}/all`)
      .then(result => result.json())
      .then(data => {
        console.log(data);
        setEvents(data);
      })
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchAllEvents();
  }, [setEvents]);

  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const cols: GridColDef[] = [
    { field: 'name', headerName: 'Naam:', flex: 3 },
    { 
      field: 'location', 
      headerName: 'Route', 
      flex: 0.3,
      sortable: false,
      renderCell: (params: GridRenderCellParams<String>) => (
        <a href={'http://maps.google.com/maps/search/here+to+' + params.value + '/'}>
          <MapIcon className="mapIcon" />
        </a>
      )
    },
    {
      field: 'id',
      headerName: 'Info',
      flex: 0.3,
      sortable: false,
      renderCell: (params: GridRenderCellParams<number>) => (
        <Link to={'/events/' + params.value}>
          {params.value}
          <InfoIcon className="infoIcon" />
        </Link>
      )
    }
  ];

  return (
    <Grid>
      <Grid
        container
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <GroupsIcon style={{ fontSize: '3rem' }} />
            <p style={{ padding: '1rem' }}>All Events </p>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClickOpen}
          >
            Create an event
          </Button>
          <EventForm open={open} handleClose={handleClose} />
        </Grid>
      </Grid>
      <Grid>
        {loading ? (
          <div>
            <LoadIcon />
          </div>
        ) : (
          <DataGrid
            className="datagrid"
            rows={events}
            columns={cols}
            page={page}
            onPageChange={page => setPage(page)}
            rowsPerPageOptions={[5, 10, 15]}
            pageSize={pageSize}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            autoHeight
          />
        )}
      </Grid>
    </Grid>
  );
}

export default EventsList;
