import React, { useState } from 'react'
import { Input, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { baseUrl } from 'api/api-endpoints';
import { SentimentSatisfiedAltRounded } from '@mui/icons-material';

export default function Register() {
  const [email, setEmail] = useState();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const doRegister = () => {
    setError(false);
    setSuccess(false);
    
    const body = JSON.stringify({ email, username, password, confirmPassword });
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: body
    }
    
    fetch(baseUrl + '/register', requestOptions)
      .then(response => {
        if(response.ok) {
          setSuccess(true);
        } else {
          setError(true)
        }
      })
      .catch(() => setError(true))
  }

  return (
    <div>
    <form>
    <h1>Registreer een muzikant</h1>
        <Input
          type="text"
          value={email}
          placeholder="email"
          onChange={e => setEmail(e.target.value)}
        /><br/>
        <Input
          type="text"
          value={username}
          placeholder="gebruikersnaam"
          onChange={e => setUsername(e.target.value)}
        /><br/>
        <Input
          type="password"
          value={password}
          placeholder="wachtwoord"
          onChange={e => setPassword(e.target.value)}
        /><br/>
        <Input
          type="password"
          value={confirmPassword}
          placeholder="wachtwoord herhalen"
          onChange={e => setConfirmPassword(e.target.value)}
        /><br/>

        { error && <p style={{ color: 'red' }}>Registreren mislukt, probeer opnieuw.</p> }
        { success && <p style={{ color: 'green' }}>Muzikant geregistreerd!</p> }

        <Button onClick={() => doRegister()}>Registreer</Button><br/>
        <Link to={'/login'}>
          <Button>Terug naar login</Button>
        </Link>
    </form>
    </div>
  )
}
