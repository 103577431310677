import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from 'react-router-dom';

interface dialogProps {
  handleClose: () => void;
  open: boolean;
  isEditing?: boolean;
  stage: IStage;
}
interface IBooking {
  name: string;
  duration: number;
  genre: string;
  confirmed: boolean;
}
interface IStage {
  id?: number;
  name: String;
  size: number;
  amountOfPeople: number;
}
interface IEvent {
  id: number;
  name: String;
  dateTime: String;
  location: String;
  stages: IStage[];
}
export default function BookingForm(props: dialogProps) {
  useEffect(() => {
    console.log(props);
  }, []);
  const {
    register,
    handleSubmit,
    control,
    trigger,
    getValues,
    reset,
    formState: { errors }
  } = useForm<IBooking>();
  const history = useHistory();

  const onSubmit: SubmitHandler<IBooking> = (data, e) => {
    e?.preventDefault();
    fetch(`https://ba.studioprimary.com/api/booking/stages/${props.stage.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(result => result.json())
      .then(data => {
        console.log(data);
        reset();
        props.handleClose();
        history.push(`/booking`);
      })
      .catch(error => {
        console.warn('Error:', error);
      });
    console.log(data, e);
  };
  const label = { inputProps: { 'aria-label': 'Confirm' } };
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          <p>{props.stage.name}</p>
          <p style={{ fontSize: '14px' }}> create booking</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              {...register('name', {
                required: 'please enter name',
                minLength: {
                  value: 3,
                  message: 'min length is 3'
                },
                maxLength: {
                  value: 20,
                  message: 'max length is 20'
                }
              })}
              id="name"
              label="Name"
            />
            <Typography color="error.main">
              {(errors.name as any)?.message}
            </Typography>
            <TextField
              {...register('duration', {
                required: 'please enter duration',
                min: {
                  value: 1,
                  message: 'min length is 1'
                },
                pattern: {
                  value: /^\d+$/,
                  message: 'Must be a number'
                }
              })}
              id="duration"
              label="duration"
              type="number"
            />
            <Typography color="error.main">
              {(errors.duration as any)?.message}
            </Typography>
            <TextField
              {...register('genre', {
                required: 'please enter location',
                minLength: {
                  value: 3,
                  message: 'min length is 3'
                },
                maxLength: {
                  value: 20,
                  message: 'max length is 20'
                }
              })}
              id="genre"
              label="Genre"
            />
            <Typography color="error.main">
              {(errors.genre as any)?.message}
            </Typography>
            <Checkbox {...label} {...register('confirmed')} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Disagree</Button>
          <Button type="submit">Create booking</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
