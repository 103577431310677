import { useEffect, useState } from 'react';

import './Home.scss';

interface Props {
  match: any;
}

export default function Home(props: Props) {
  return <div>Hello world!</div>;
}
