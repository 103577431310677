import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'


export default function CreateBand() {

    useEffect(() => {
        // component mount



        return () => {
            // component remove
        }
        // update when there items change -> array
    }, [])

    const [bandName, setBandName] = useState('')

    const createBand = () => {
        fetch('https://ba.studioprimary.com/api/createband', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: bandName
            })
        })
    }

    return (
        <div>
            <h1>Create Band page</h1>
            <div>
                <TextField size='small' required onChange={(e) => setBandName(e.target.value)} label='Band name' color='secondary' variant='outlined' />
                <div>
                    <Button variant='outlined' color='secondary' onClick={createBand}>Create</Button>
                </div>
            </div>
        </div>
    )
}