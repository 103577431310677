import {
  DialogActions,
  DialogContent,
  Dialog,
  TextField,
  Grid
} from '@mui/material/';
// import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import { postEvent } from '../../api/requests/postEvent';
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import { useHistory } from 'react-router-dom';

interface dialogProps {
  handleClose: () => void;
  open: boolean;
  isEditing?: boolean;
}
interface IStage {
  id?: number;
  name: String;
  size: number;
  amountOfPeople: number;
}
interface IEvent {
  name: String;
  location: String;
  dateTimeEvent?: Date;
  dateTime?: String;
  stages?: IStage[];
}

function EventForm(props: dialogProps) {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    trigger,
    getValues,
    reset,
    formState: { errors }
  } = useForm<IEvent>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'stages'
  });

  useEffect(() => {
    console.log(props);
  }, [props]);

  const [activeStep, setActiveStep] = useState(0);
  const [stages, setStages] = useState<IStage[]>([]);
  const [event, setEvent] = useState<IEvent | null>();
  const [value, setValue] = useState<Date | null>(
    new Date('2014-08-18T21:11:54')
  );

  const handleChange = (dateTimeEvent: Date | null) => {
    setValue(dateTimeEvent);
  };
  const handleNext = (e: any) => {
    e.preventDefault();
    if (activeStep === 0) {
      const bool = trigger(['name', 'location']).then(result => {
        return result;
      });
      if (!bool) {
        trigger('name');
        return;
      }
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    reset();
  };

  const createEvent = (data: IEvent) => {
    console.log(data);
  };
  const steps = [
    {
      label: 'Insert event information'
    },
    {
      label: 'Insert stage information'
    }
  ];

  const onSubmit: SubmitHandler<IEvent> = (data, e) => {
    e?.preventDefault();
    console.log(data, e);
    const requestData = Object.assign({}, { ...data });
    const ah = data.dateTimeEvent;
    delete requestData.dateTimeEvent;
    if (ah !== undefined) {
      const date = new Date(ah);
      var isoDateString = date.toISOString();
      requestData.dateTime = isoDateString;
    }
    fetch('https://ba.studioprimary.com/api/events?userId=1', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })
      .then(result => result.json())
      .then(data => {
        console.log(data);
        setActiveStep(0);
        reset();
        props.handleClose();
        history.push(`/events/${data.id}`);
      })
      .catch(error => {
        console.warn('Error:', error);
      });
  };
  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create event</DialogTitle>
        <DialogContent>
          <DialogContentText>Create an event here.</DialogContentText>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index === 2 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent>
                    <Box sx={{ mb: 2 }}>
                      <Grid>
                        {index === 0 ? (
                          <Box>
                            <Grid>
                              <TextField
                                {...register('name', {
                                  required: 'please enter location',
                                  minLength: {
                                    value: 3,
                                    message: 'min length is 3'
                                  },
                                  maxLength: {
                                    value: 20,
                                    message: 'max length is 20'
                                  }
                                })}
                                id="name"
                                label="Set name for event"
                              />

                              <Typography color="error.main">
                                {(errors.name as any)?.message}
                              </Typography>

                              <TextField
                                {...register('location', {
                                  required: 'please enter email',
                                  minLength: {
                                    value: 3,
                                    // type: 'manual',
                                    message: 'min length is 3'
                                  },
                                  maxLength: {
                                    value: 20,
                                    message: 'max length is 20'
                                  }
                                })}
                                id="location"
                                label="Set location for event"
                              />
                              <Typography color="error.main">
                                {(errors.location as any)?.message}
                              </Typography>
                              <LocalizationProvider dateAdapter={DateAdapter}>
                                <Stack spacing={3}>
                                  <DateTimePicker
                                    {...register('dateTimeEvent')}
                                    label="Date&Time picker"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params: any) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </Grid>
                          </Box>
                        ) : null}
                        {index === 1 ? (
                          <Grid>
                            <Grid>
                              {fields.map((item, index) => {
                                return (
                                  <div key={item.id}>
                                    <label>{index}</label>
                                    <TextField
                                      // name={`stages[${index}].name`}
                                      defaultValue={`${item.name}`} // make sure to set up defaultValue
                                      {...register(`stages.${index}.name`)}
                                    />

                                    <TextField
                                      // name={`stages[${index}].size`}
                                      defaultValue={`${item.size}`} // make sure to set up defaultValue
                                      {...register(`stages.${index}.size`)}
                                    />

                                    <TextField
                                      // name={`stages[${index}].size`}
                                      defaultValue={`${item.amountOfPeople}`} // make sure to set up defaultValue
                                      {...register(
                                        `stages.${index}.amountOfPeople`
                                      )}
                                    />
                                    <Button
                                      type="button"
                                      onClick={() => {
                                        remove(0);
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                );
                              })}
                            </Grid>
                            <Button
                              type="button"
                              onClick={() => {
                                append({
                                  name: '',
                                  size: 0,
                                  amountOfPeople: 0
                                });
                              }}
                            >
                              Create a stage
                            </Button>
                          </Grid>
                        ) : null}
                        <Button
                          variant="contained"
                          onClick={e => {
                            handleNext(e);
                          }}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? 'Finish' : 'Continue'}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </Grid>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={props.handleClose}
                >
                  Create event
                </Button>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          {/* <div>
            {errors.name && (
              <Typography color="error.main">
                {(errors.name as any)?.message}
              </Typography>
            )}
          </div>
          <div>
            {errors.location && (
              <Typography color="error.main">
                {(errors.location as any)?.message}
              </Typography>
            )}
          </div> */}
        </DialogActions>
      </form>
      {/* {(errors.name as any)?.message} */}
    </Dialog>
  );
}

export default EventForm;
