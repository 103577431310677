import * as React from 'react';

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PeopleIcon from '@mui/icons-material/People';

import './Navigation.scss';
import { Link } from 'react-router-dom';

export default function Navigation() {
  if (window.location.pathname === '/login') return null;
  return (
    <Paper id="navigation">
      <MenuList id="left-nav">
        <div>
          <Link
            to="/booking"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <MenuItem>
              <ListItemIcon>
                <BookmarkBorderIcon fontSize="small" />
              </ListItemIcon>

              <ListItemText>
                <Link
                  to="/booking"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  Booking
                </Link>
              </ListItemText>
            </MenuItem>
          </Link>
          <Link to="/events">
            <MenuItem>
              <ListItemIcon>
                <CalendarTodayIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Events</ListItemText>
            </MenuItem>
          </Link>
          <MenuItem>
            <ListItemIcon>
              <ListIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>To-Do's</ListItemText>
          </MenuItem>
          <Link
            to="/allbands"
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <MenuItem>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Bands</ListItemText>
            </MenuItem>
          </Link>
        </div>
        <div className="to-bottom">
          <Divider />
          <MenuItem>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </div>
      </MenuList>
    </Paper>
  );
}
