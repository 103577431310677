import { Grid, Button, Box, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ApiEndpoints, { baseUrl } from '../../api/api-endpoints';
import GroupsIcon from '@mui/icons-material/Groups';
import EventIcon from '@mui/icons-material/Event';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import LoadIcon from 'components/LoadIcon/LoadIcon';
import BookingForm from './BookingForm';

interface Props {
  match: any;
}
interface IStage {
  name: String;
  size: number;
  amountOfPeople: number;
}
interface IEvent {
  id: number;
  name: String;
  dateTime: String;
  location: String;
  stages: IStage[];
}

export default function Event(props: Props) {
  const [open, setOpen] = React.useState(false);

  const [event, setEvent] = useState<IEvent>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${baseUrl}/${ApiEndpoints.events}/get/${props.match.params.id}`)
      .then(result => result.json())
      .then(data => {
        console.log(data);
        setEvent(data);
      })
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid>
      {!loading ? (
        <React.Fragment>
          <Grid container>
            <Grid item>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <EventIcon style={{ fontSize: '3rem' }} />
                {event?.name && (
                  <p style={{ padding: '1rem' }}>{event?.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <div></div>
            </Grid>
          </Grid>
          <Grid item>
            <h1>{event?.name}</h1>
            <ul style={{ listStyle: 'none' }}>
              <li>
                <span style={{ fontWeight: 'bold' }}> Name: </span>{' '}
                {event?.name}
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}> Location: </span>
                {event?.location}
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}> Date: </span>
                {event?.dateTime &&
                  event.dateTime.toString().split('T')[0] +
                    ' ' +
                    event.dateTime.toString().split('T')[1].split(':')[0] +
                    ':' +
                    event.dateTime.toString().split('T')[1].split(':')[1]}
              </li>
              <li>
                <Grid container direction="column">
                  <Grid item>
                    <p style={{ fontWeight: 'bold' }}> Stages </p>
                  </Grid>
                  <Grid item style={{ marginTop: '20px' }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      {event?.stages &&
                        event?.stages.map((stage, index) => {
                          return (
                            <Grid item xs={4}>
                              <Card>
                                <CardContent>
                                  <Typography variant="h5" gutterBottom>
                                    {stage.name}
                                  </Typography>
                                  <Typography
                                    color="text.secondary"
                                    component="div"
                                  >
                                    <ul
                                      style={{
                                        listStyle: 'none',
                                        margin: '0',
                                        padding: '0'
                                      }}
                                    >
                                      <li>Total people: {stage.size} </li>
                                      <li>
                                        Amount:
                                        {stage.amountOfPeople}
                                      </li>
                                    </ul>
                                  </Typography>
                                  <Typography variant="body2">
                                    <BookingForm
                                      open={open}
                                      handleClose={handleClose}
                                      stage={stage}
                                    />
                                    <Button
                                      variant="outlined"
                                      color="secondary"
                                      onClick={handleClickOpen}
                                    >
                                      Add booking
                                    </Button>
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </li>
            </ul>
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {' '}
          <LoadIcon />
        </React.Fragment>
      )}
    </Grid>
  );
}
// const style = {
//   position: 'absolute' as 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4
// };
