import React, { Component, createContext } from 'react';
import { baseUrl } from 'api/api-endpoints';

export const UserContext = createContext();

export default class UserContextProvider extends Component {
  constructor(props) {
    super(props);

    if (JSON.parse(sessionStorage.getItem('state'))) {
      this.state = JSON.parse(sessionStorage.getItem('state'));
    } else {
      this.state = {
        isLoggedIn: false, // set to false
        token: '',
        user: {
          id: 0,
          name: 'tim',
          username: 'hardcoded'
        }
      };
    }
  }

  removeUserFromContext = () => {
    this.setState({ user: { id: 0 }, isLoggedIn: false });
    sessionStorage.clear();
  };

  saveUserToContext = user => {
    this.setState({ user, isLoggedIn: true });
  };

  saveToSessionStorage() {
    sessionStorage.setItem('state', JSON.stringify(this.state));
  }

  saveToken = async token => {
    this.setState({ token, isLoggedIn: true });
    await this.getUserInfo();
    this.saveToSessionStorage();
  };

  getUserInfo = async () => {
    await fetch(baseUrl + '/user/me', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.state.token,
        'Access-Control-Allow-Origin': '*'
      }
    })
      .then(res => res.json())
      .then(data => this.saveUserToContext(data))
      .catch(e => console.log(e));
  };
  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          saveToken: this.saveToken,
          removeUserFromContext: this.removeUserFromContext
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
