import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorRounded } from '@mui/icons-material';
import { Alert, Button, Input, Snackbar } from '@mui/material';
import { UserContext } from 'context/UserContext';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import style from './Login.module.scss';
import { baseUrl } from 'api/api-endpoints';

export default function Login(props: any) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginError, setloginError] = useState<string | null>(null);

  const context = useContext(UserContext);
  let history = useHistory();
  const login = () => {
    var credentials = { username: username, password: password };
    setLoading(true);
    fetch(baseUrl + '/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(credentials)
    }).then(async res => {
      setLoading(false);
      if (res.ok) {
        const token = await res.text();
        context.saveToken(token);
        history.push('/');
      } else {
        setloginError(await res.text());
      }
    });
  };

  const form = () => {
    return (
      <form className={style.form}>
        <h1 className={style.title} onClick={() => props.history.push('/')}>
          BandAssistant
        </h1>
        <h2 className={style.slogan}>Login to see your gigs</h2>
        <Input
          type="text"
          value={username}
          placeholder="username"
          onChange={e => setUsername(e.target.value)}
        />
        <Input
          type="password"
          value={password}
          placeholder="Password"
          onChange={e => setPassword(e.target.value)}
        />
        <Button onClick={() => login()}>Login</Button>
        <hr />
        <Link to={'/register'}>
          <Button>Register</Button>
        </Link>
      </form>
    );
  };

  return (
    <div className="full-page">
      <Snackbar
        open={loginError != null}
        autoHideDuration={6000}
        onClose={() => setloginError(null)}
      >
        <Alert
          onClose={() => setloginError(null)}
          severity="error"
          sx={{ width: '100%' }}
        >
          {loginError}
        </Alert>
      </Snackbar>
      <div className={style.login}>
        {loading ? <FontAwesomeIcon icon={faSpinner} /> : form()}
      </div>
    </div>
  );
}
