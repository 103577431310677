import './App.scss';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useEffect } from 'react';

import Home from './screens/Home/Home';
import Band from './screens/Band/Band';
import Booking from './screens/Booking/Booking';
import CreateBooking from './screens/Booking/CreateBooking';
import Register from './screens/User/Register';
import Login from './screens/Login/Login';
import Navigation from './components/nav/Navigation';
import { Grid } from '@mui/material';
import AllBands from './components/Band/AllBands';
import CreateBand from './screens/Band/CreateBand';
import UserContextProvider from 'context/UserContext';
import EventsList from './components/Event/EventsList';

import Event from './components/Event/Event';

// import PrivateRoute from 'components/PrivateRoute';

function App() {
  return (
    <UserContextProvider>
      <Grid container spacing={0} className="App">
        <Router>
          <Route exact path="/login" component={Login} />
          <Grid item xs={2}>
            <Navigation />
          </Grid>
          <Grid item xs={9}>
            <Switch>
              <Route exact path="/register" component={Register} />
              <Route exact path="/" component={Home} />
              <Route exact path="/band/:id" component={Band} />
              <Route exact path="/booking" component={Booking} />
              <Route exact path="/booking/view/:id" component={Booking} />
              <Route exact path="/booking/create" component={CreateBooking} />
              <Route exact path="/allbands" component={AllBands} />
              <Route exact path="/createband" component={CreateBand} />
              <Route exact path="/events" component={EventsList} />
              <Route exact path="/events/:id" component={Event} />
            </Switch>
          </Grid>
        </Router>
      </Grid>
    </UserContextProvider>
  );
}
export default App;
