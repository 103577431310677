import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
// @ts-ignore
import { Link } from 'react-router-dom';
import LoadIcon from '../../components/LoadIcon/LoadIcon';

import './Booking.scss';

export type BookingObject = {
  id: number;
  name: string;
  duration: number;
  genre: string;
  confirmed: boolean;
};

interface Props {
  match: any;
}

export default function Booking(props: Props) {
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    fetch('https://ba.studioprimary.com/api/getAllBookings')
      .then(response => response.json())
      .then(data => setBookings(data))
      .catch(() => console.log('failed to fetch'));
  }, []);

  if (bookings.length === 0 || bookings === null) {
    return (
      <div>
        <h1 className="header">Booking page</h1>
        <div className="loading">
          <LoadIcon />
        </div>
      </div>
    );
  }

  if (props.match.params.id != null) {
    const paramId: number = Number(props.match.params.id);
    var filteredBooking =
      bookings.find((booking: BookingObject) => booking.id === paramId) || null;
    if (filteredBooking) {
      const booking: BookingObject = filteredBooking as BookingObject;
      return (
        <div className="singleBooking">
          <p>
            {booking.name} | {booking.duration} | {booking.genre} |
            {booking.confirmed ? 'Confirmed' : 'Hot Prospect'}
          </p>
        </div>
      );
    } else {
      return <h1>404 - Booking not found</h1>;
    }
  } else {
    const cols: GridColDef[] = [
      { field: 'name', headerName: 'Naam:', flex: 3 },
      { field: 'duration', headerName: 'Lengte:', flex: 1 },
      { field: 'genre', headerName: 'Genre:', flex: 1 },
      { field: 'confirmed', headerName: 'Bevestigd', flex: 0.3 },
      {
        field: 'id',
        headerName: 'Info',
        flex: 0.3,
        sortable: false,
        renderCell: (params: GridRenderCellParams<number>) => (
          <Link to={'/booking/view/' + params.value}>
            <InfoIcon className="infoIcon" />
          </Link>
        )
      }
    ];

    return (
      <Grid>
        <Grid container spacing={0}>
          <h1>All bookings </h1>
          <Link to="/booking/create" className="createButton">
            <Button variant="outlined" color="secondary">
              Create Booking
            </Button>
          </Link>
        </Grid>
        <Grid>
          <DataGrid
            className="datagrid"
            rows={bookings}
            columns={cols}
            autoHeight
            disableColumnMenu
          />
        </Grid>
      </Grid>
    );
  }
}
