import React, { useEffect, useState } from 'react'

import "./Band.scss"

interface Props {
    match: any;
}

type Band = {
    name: string;
    users: any;
}

export default function BandPage(props: Props) {


    const [band, setBand] = useState<Band | undefined>();

    useEffect(() => {
        // component mount
        fetch('https://ba.studioprimary.com/api/bandbyid/' + props.match.params.id)
            .then(response => response.json())
            .then(data => setBand(data))
            .catch(() => console.log('failed to fetch'));

        return () => {
            // component remove
        }
        // update when there items change -> array
    }, [])


    return (
        <div>
            <h1>{band?.name}</h1>
            <h3>Leden:</h3>
            {band?.users.map((user:any, index:number) => (
                <div key={index}>
                    <h3>{user.name}</h3>
                </div>
            ))}
        </div>
    )

}
