import { ChangeEvent, FormEvent, useState } from 'react';
import { BookingObject } from './Booking';
import './Booking.scss';

export default function CreateBooking() {
    const [state, setState] = useState({});

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        var value;
        if(event.target.type === "checkbox") {
            value = event.target.value === "on" ? true : false;
        } else if (event.target.type === "number") {
            value = Number(event.target.value);
        } else {
            value = event.target.value;
        }
        setState({...state, [event.target.name]: value});
    }

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        const booking: BookingObject = state as BookingObject;
        // convert confirmed value to bool
        // booking.confirmed = booking.confirmed === "on" ? true : false;
        console.log(booking);

        const requestOptions = {
            headers: { 'Content-Type': 'application/json' },
            method: "POST",
            body: JSON.stringify(booking)
        }

        fetch('https://ba.studioprimary.com/api/createbooking', requestOptions)
            .then(response => console.log("Response: ", response))
            .catch(error => console.log("Error: ", error));
    }

    return (
        <div>
            <h1>Create booking</h1>
            <form onSubmit={handleSubmit}>
            <table>
            <tr>
                <td><label htmlFor="name">Naam:</label></td>
                <td><input type="text" name="name" onChange={handleChange} /><br /></td>
            </tr>
            <tr>
                <td><label htmlFor="duration">Lengte:</label></td>
                <td><input type="number" name="duration" onChange={handleChange} /><br /></td>
            </tr>
            <tr>
                <td><label htmlFor="genre">Genre:</label></td>
                <td><input type="text" name="genre" onChange={handleChange} /><br /></td>
            </tr>
            <tr>
                <td><label htmlFor="confirmed">Bevestigd:</label></td>
                <td><input type="checkbox" name="confirmed" onChange={handleChange} /><br /></td>
            </tr>
            <tr><td></td>
                <td><input type="submit" value="Maak booking aan" /></td>
            </tr>
            </table>
            </form>
        </div>
    );
}
